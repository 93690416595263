import React from 'react'
// import becomeImg from  '../../assets/images/become-youtuber.jpeg'
import becomeImg from  '../../assets/images/katt-yukawa-K0E6E0a0R3A-unsplash.jpg'

import { useNavigate } from 'react-router-dom';


const BecomeYoutuber = () => {

  
    const navigate = useNavigate();
  
    const handleClick = () => {
      navigate('/donation');
    };

  return (
    <div style={{backgroundImage: `url(${becomeImg})`}} className={`py-16 sm:py-28 md:py-60 mb-12  sm:mb-20 relative before:absolute before:w-full before:h-full before:bg-black before:bg-opacity-75 flex items-center bg-cover bg-no-repeat bg-center 2xl:bg-positionXY`} >
        <div className='text-center max-w-4xl mx-auto z-10'>
          <h2 className='pb-4 text-white text-xl lg:text-4xl md:text-3xl font-bold m-0 break-words'>Join Our Community</h2>
          <p className='px-12 leading-7 font-normal text-lg text-white m-0 break-words'>
           A remarkable fundraising platform with a mission to bring smiles to the faces of the underprivileged and vulnerable communities, the club operates as a beacon of hope through various events, campaigns, and partnerships with generous donors and businesses. The club also creates a sense of community empowerment and highlights the potential for collective action to tackle complex challenges like poverty.          </p>
          <div className='mt-10 items-center flex flex-col sm:flex-row justify-center gap-4'>
            {/* <button className='rounded-3xl py-2 px-6 text-base font-bold tracking-0.5 transition-all duration-0.5 text-white hover:text-primary border-2 border-primary bg-primary hover:bg-transparent'>
              Become a Volunteer
            </button> */}
             <button onClick={handleClick}  className='rounded-3xl py-2 px-6 text-base font-bold tracking-1.25 transition-all duration-0.5  border-2 text-tertiary hover:text-gray-800 transparent hover:bg-tertiary hover:border-tertiary'>
             Donate Now
            </button>
          </div>
        </div>
    </div>
  )
}

export default BecomeYoutuber