import React, { useState } from "react";
// import aboutImg from '../../assets/images/about.jpeg'
import aboutImg from "../../assets/images/rq.jpg";

import { Link } from "react-router-dom";
import Icons from "../global/Icons";

const data = [
  {
    id: 1,
    icon: Icons.worldIcon,
    title: "Natural Coffee Beans",
    content:
      "Sed ut perspiciatis unde omnis iste natus error voluptate accusantium doloremque",
  },
  {
    id: 2,
    icon: Icons.donateMoneyIcon,
    title: "Natural Coffee Beans",
    content:
      "Sed ut perspiciatis unde omnis iste natus error voluptate accusantium doloremque",
  },
  {
    id: 3,
    icon: Icons.suportIcon,
    title: "Natural Coffee Beans",
    content:
      "Sed ut perspiciatis unde omnis iste natus error voluptate accusantium doloremque",
  },
];

const AboutSecetion = () => {
  const [items, setItems] = useState([...data]);

  return (
    <div style={{paddingBottom:'0rem',paddingTop:'0rem'}} className={`py-12 sm:py-20 bg-on-primary-opacity-0.16`}>
      {/* <div className='container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-10'>
        <h4 className='text-primary font-bold text-sm m-0 break-words uppercase tracking-widest'>About Us</h4>
        <h4 className='font-bold m-0 break-words text-xl lg:text-5xl md:text-3xl text-gray-900 max-w-xl'>Our Highest Ambition is to Help People</h4>
        <div className='flex flex-col lg:flex-row justify-between pb-14'>
          <div className='basis-80%'>
            <p className='text-gray-700 pt-2 leading-7 font-normal text-lg m-0 break-words'>
            GNFoundation is a non-profit organization dedicated to making a meaningful impact in the community through two primary initiatives: operating free food community kitchens and providing scholarships for orphan students.

Since our inception, GNFoundation has served thousands of meals to those in need and awarded numerous scholarships to deserving orphan students. We measure our success not only by the number of meals served and scholarships granted but also by the positive changes we see in the lives of those we support.            </p>

<div className='basis-20% flex justify-start mt-4 lg:mt-0 lg:justify-end items-center'>
            <Link to="/about-us" className='text-base font-bold tracking-0.5 py-2 px-6 rounded-3xl transition-all duration-0.5 text-white hover:text-primary border-primary border-2 bg-primary hover:bg-transparent'>
              Learn more
            </Link>
          </div>
          </div>
          
        </div>

         <div>
          <img className='border-b-8 border-primary rounded-custom-100' src={aboutImg} alt="" />
          
        </div>
        <div className='pt-14'>
          <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-7 md:gap-4'>
            {
              items?.map((item,i) => (
                <div key={i} className='flex gap-2 sm:gap-5 flex-col sm:flex-row'>
                  <div>
                    {item.icon}
                  </div>
                  <div>
                    <h5 className='text-black font-bold text-lg sm:text-2xl'>
                      {item.title}
                    </h5>
                    <p className='leading-7 font-normal text-lg text-black m-0 break-words'>
                      {item.content}
                    </p>
                  </div>
                </div>
              ))
            }
          </div>
        </div> 
      </div> */}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 p-8">
        <div>
          {/* <img style={{}} className='rounded-bra-100 object-cover' src={aboutImg}  alt="img"/> */}

          <img
            style={{
              transition: "transform 0.3s ease", // Smooth transition for the transformation
              cursor: "pointer", // Change cursor to pointer on hover
            }}
            className="rounded-bra-100 object-cover"
            src={aboutImg}
            alt="img"
            onMouseOver={(e) =>
              (e.currentTarget.style.transform = "scale(1.05)")
            } // Scale up on hover
            onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")} // Scale back on mouse out
          />
        </div>
        <div className="flex justify-center items-center">
          <div>
            <h4 className="text-primary font-bold text-sm m-0 break-words uppercase tracking-widest">
              About Us
            </h4>
            <h4 className="pt-2 text-gray-900 text-xl lg:text-3xl md:text-3xl font-bold m-0 break-words">
              About Armor Charitable Trust
            </h4>
            {/* <h4 className='pt-4 text-gray-900 lg:text-2xl md:text-xl text-lg font-bold m-0 break-words'>Helping them</h4> */}
            <p className="pt-2 leading-7 font-normal text-lg text-gray-700 m-0 break-words">
              Armor Charitable Trust is dedicated to the holistic upliftment of
              society through education, healthcare, social welfare, and
              cultural programs. Our work is inspired by the ideals of service,
              aiming to make a positive impact on vulnerable communities,
              especially in Kerala, with a focus on education, healthcare, and
              community support.{" "}
            </p>
            {/* <button className='mt-10 rounded-3xl py-2 px-6 text-base font-bold tracking-0.5 transition-all duration-0.5 text-white hover:text-primary border-2 border-primary bg-primary hover:bg-transparent'>
                            Learn More
                        </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSecetion;
