// import React from 'react'
// import meet1Img from '../../assets/images/meet1.jpeg'
// import meet2Img from '../../assets/images/meet2.jpeg'
// import meet3Img from '../../assets/images/meet3.jpeg'
// import meet4Img from '../../assets/images/meet4.jpeg'
// import { Link } from 'react-router-dom'
// import Icons from '../global/Icons'


// const MeetOurTeam = () => {

//   const meetArray = [
//     {
//       id: 1,
//       title: "Devil Roy Barman",
//       subTitle: "Volunteer",
//       img: meet1Img,
//       socialMedia: [
//         {
//           id: 1,
//           icon: Icons.fbIcon,
//           link: '#'
//         },
//         {
//           id: 2,
//           icon: Icons.linkedinIcon,
//           link: '#'
//         },
//         {
//           id: 3,
//           icon: Icons.twitterIcon,
//           link: '#'
//         },
//       ]
//     },
//     {
//       id: 2,
//       title: "Devil Roy Barman",
//       subTitle: "Volunteer",
//       img: meet2Img,
//       socialMedia: [
//         {
//           id: 1,
//           icon: Icons.fbIcon,
//           link: '#'
//         },
//         {
//           id: 2,
//           icon: Icons.linkedinIcon,
//           link: '#'
//         },
//         {
//           id: 3,
//           icon: Icons.twitterIcon,
//           link: '#'
//         },
//       ]
//     },
//     {
//       id: 3,
//       title: "Devil Roy Barman",
//       subTitle: "Volunteer",
//       img: meet3Img,
//       socialMedia: [
//         {
//           id: 1,
//           icon: Icons.fbIcon,
//           link: '#'
//         },
//         {
//           id: 2,
//           icon: Icons.linkedinIcon,
//           link: '#'
//         },
//         {
//           id: 3,
//           icon: Icons.twitterIcon,
//           link: '#'
//         },
//       ]
//     },
//     {
//       id: 4,
//       title: "Devil Roy Barman",
//       subTitle: "Volunteer",
//       img: meet4Img,
//       socialMedia: [
//         {
//           id: 1,
//           icon: Icons.fbIcon,
//           link: '#'
//         },
//         {
//           id: 2,
//           icon: Icons.linkedinIcon,
//           link: '#'
//         },
//         {
//           id: 3,
//           icon: Icons.twitterIcon,
//           link: '#'
//         },
//       ]
//     }

//   ]

//     let contentViewRender = null
//     contentViewRender = meetArray.map((item) => (
//       <div className='relative overflow-hidden group' key={item.id}>
//         <div className='absolute -right-11  top-3 group-hover:right-3 transition-right duration-0.5'>
//           <div className="flex flex-col items-center justify-center gap-2">
//             {
//               item.socialMedia?.map((single) => (
//                 <Link to={single.link} className="cursor-pointer bg-secondary p-2 rounded-md">
//                   {single.icon}
//                 </Link>
//               ))
//             }

//           </div>
//         </div>
//         <img className=' max-h-max md:max-h-80 2xl:max-h-96 sm:h-full w-full object-cover' src={item.img} alt="team" />

//         <h4 className='pt-4 pb-1 text-gray-900 lg:text-2xl md:text-xl text-lg font-bold m-0 break-words'>{item.title}</h4>
//         <h5 className="text-gray-900 text-md font-bold">{item.subTitle}</h5>
//       </div>
//     ))

//   return (
//     <div className="py-12 sm:py-20">
//       <div className='container mx-auto px-4 text-center'>
//         <h4 className='pb-2 text-primary font-bold text-sm m-0 break-words uppercase tracking-widest'>
//           We Change Your Life & World
//         </h4>
//         <h2 className='mx-auto w-full max-w-lg pb-4 text-gray-900 text-xl lg:text-5xl md:text-3xl font-bold m-0 break-words'>Meet Our Volunteers</h2>
//         <p className='lg:max-w-5xl max-w-3xl mx-auto w-full pb-12 leading-7 font-normal text-lg text-gray-700 m-0 break-words'>
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit. In id augue semper arcu enim viverra sit ipsum. Nunc lorem a vulputate eget bibendum id et. Eget nunc, eu, molestie tellus sollicitudin vitae consequat adipiscing.
//         </p>

//       </div>
//       <div className='container mx-auto px-4'>
//         <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6'>
//           {contentViewRender}
//         </div>
//         <div className='text-center pt-10'>
//           <Link to="/meet-team" className="text-base font-bold tracking-0.5 border-primary bg-primary hover:bg-transparent py-2 px-6 rounded-3xl transition-all duration-0.5 text-white hover:text-primary border-2">
//             Learn More
//           </Link>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default MeetOurTeam


import React from 'react'
import meet1Img from '../../assets/images/profile.jpg'
import meet2Img from '../../assets/images/profile.jpg'
import meet3Img from '../../assets/images/profile.jpg'
import meet4Img from '../../assets/images/profile.jpg'
import { Link } from 'react-router-dom'
import Icons from '../global/Icons'


const MeetOurTeam = () => {

  const meetArray = [
    {
      id: 1,
      title: "Abuzakeer P.S.",
      subTitle: "Chairman&Managing Trustee",
      // : Agriculturalist, dedicated to social service for over 59 years.",
      img: meet2Img,
      // socialMedia: [
      //   {
      //     id: 1,
      //     icon: Icons.fbIcon,
      //     link: '#'
      //   },
      //   {
      //     id: 2,
      //     icon: Icons.linkedinIcon,
      //     link: '#'
      //   },
      //   {
      //     id: 3,
      //     icon: Icons.twitterIcon,
      //     link: '#'
      //   },
      // ]
    },
    {
      id: 2,
      title: "Abdul Rahiman",
      subTitle: "Vice Chairman",
      // : Experienced businessman with a passion for community welfare, 58 years old.",
      img: meet2Img,
      // socialMedia: [
      //   {
      //     id: 1,
      //     icon: Icons.fbIcon,
      //     link: '#'
      //   },
      //   {
      //     id: 2,
      //     icon: Icons.linkedinIcon,
      //     link: '#'
      //   },
      //   {
      //     id: 3,
      //     icon: Icons.twitterIcon,
      //     link: '#'
      //   },
      // ]
    },
    {
      id: 3,
      title: "A. Jamal",
      subTitle: "General Secretary",
      // : Entrepreneur actively involved in charity for over 47 years.",
      img: meet3Img,
      // socialMedia: [
      //   {
      //     id: 1,
      //     icon: Icons.fbIcon,
      //     link: '#'
      //   },
      //   {
      //     id: 2,
      //     icon: Icons.linkedinIcon,
      //     link: '#'
      //   },
      //   {
      //     id: 3,
      //     icon: Icons.twitterIcon,
      //     link: '#'
      //   },
      // ]
    },
    {
      id: 4,
      title: "U. Shahjahan",
      subTitle: "Joint Secretary",
      // : Business leader with 59 years of experience in philanthropy.",
      img: meet4Img,
      // socialMedia: [
      //   {
      //     id: 1,
      //     icon: Icons.fbIcon,
      //     link: '#'
      //   },
      //   {
      //     id: 2,
      //     icon: Icons.linkedinIcon,
      //     link: '#'
      //   },
      //   {
      //     id: 3,
      //     icon: Icons.twitterIcon,
      //     link: '#'
      //   },
      // ]
    },
    {
      id: 5,
      title: "Nasruddin K",
      subTitle: "Treasurer",
      // : [Insert more details, if needed]",
      img: meet4Img,  // You can replace with another image if needed
      // socialMedia: [
      //   {
      //     id: 1,
      //     icon: Icons.fbIcon,
      //     link: '#'
      //   },
      //   {
      //     id: 2,
      //     icon: Icons.linkedinIcon,
      //     link: '#'
      //   },
      //   {
      //     id: 3,
      //     icon: Icons.twitterIcon,
      //     link: '#'
      //   },
      // ]
    }
  ]

  let contentViewRender = null
  contentViewRender = meetArray.map((item) => (
    // <div className='relative overflow-hidden group' key={item.id}>
    //   <div className='absolute -right-11 top-3 group-hover:right-3 transition-right duration-0.5'>
    //     <div className="flex flex-col items-center justify-center gap-2">
    //       {
    //         item.socialMedia?.map((single) => (
    //           <Link to={single.link} className="cursor-pointer bg-secondary p-2 rounded-md" key={single.id}>
    //             {single.icon}
    //           </Link>
    //         ))
    //       }
    //     </div>
    //   </div>
    //   <img className=' max-h-max md:max-h-80 2xl:max-h-96 sm:h-full w-full object-cover' src={item.img} alt="team" />

    //   <h4 className='pt-4 pb-1 text-gray-900 lg:text-2xl md:text-xl text-lg font-bold m-0 break-words'>{item.title}</h4>
    //   <h5 className="text-gray-900 text-md font-bold">{item.subTitle}</h5>
    // </div>

    <div className='card bg-white shadow-lg rounded-lg overflow-hidden relative group' key={item.id}>
      {/* Social Media Links */}
      <div className='absolute -right-11 top-3 group-hover:right-3 transition-right duration-500'>
        <div className="flex flex-col items-center justify-center gap-2">
          {item.socialMedia?.map((single) => (
            <Link to={single.link} className="cursor-pointer bg-secondary p-2 rounded-md" key={single.id}>
              {single.icon}
            </Link>
          ))}
        </div>
      </div>

      {/* Card Image */}
      <div className='card-image'>
        <img className='max-h-max md:max-h-80 2xl:max-h-96 sm:h-full w-full object-cover transition-transform duration-300 ease-in-out transform hover:scale-110' src={item.img} alt="team" />
      </div>

      {/* Card Content */}
      <div className='card-content p-4'>
        <h4 className='pt-4 pb-1 text-gray-900 lg:text-2xl md:text-xl text-lg font-bold m-0 break-words text-center'>
          {item.title}
        </h4>
        <h5 className="text-gray-900 text-md text-center text-sm font-medium ">{item.subTitle}</h5>
      </div>
    </div>
  ))

  return (
    <div className="py-12 sm:py-20" style={{paddingTop:'0rem'}}>
      <div className='container mx-auto px-4 text-center'>
        <h4 className='pb-2 text-primary font-bold text-sm m-0 break-words uppercase tracking-widest'>
          We Change Your Life & World
        </h4>
        <h2 className='mx-auto w-full max-w-lg pb-4 text-gray-900 text-xl lg:text-4xl md:text-3xl font-bold m-0 break-words'>Meet Our Core Team</h2>
        {/* <p className='lg:max-w-5xl max-w-3xl mx-auto w-full pb-12 leading-7 font-normal text-lg text-gray-700 m-0 break-words'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. In id augue semper arcu enim viverra sit ipsum. Nunc lorem a vulputate eget bibendum id et. Eget nunc, eu, molestie tellus sollicitudin vitae consequat adipiscing.
        </p> */}
      </div>
      <div className='container mx-auto px-4'>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6'>
          {contentViewRender}
        </div>
        {/* <div className='text-center pt-10'>
          <Link to="/meet-team" className="text-base font-bold tracking-0.5 border-primary bg-primary hover:bg-transparent py-2 px-6 rounded-3xl transition-all duration-0.5 text-white hover:text-primary border-2">
            Learn More
          </Link>
        </div> */}
      </div>
    </div>
  )
}

export default MeetOurTeam
