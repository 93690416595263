// import React from 'react'
// import Slider1 from "react-slick";
// import slideDataArray from './SlideData'
// import Icons from '../global/Icons'
// import { useState } from 'react';
// import upcommingImg from '../../assets/images/upcomming-event.jpeg'
// import './upcomming-event.css'

// const SampleNextArrow = ({ onClick }) => {
//   return (
//     <div onClick={onClick} className='z-1  hidden absolute -top-0 lg:-top-16 right-14 md:right-20 cursor-pointer sm:inline-block transition-all duration-0.5 hover:bg-on-secondary-container bg-secondary p-1.5 md:p-3 2xl:p-4 rounded-lg'>{Icons.leftWihteArrow}</div>
//   )
// }
// const SamplePrevArrow = ({ onClick }) => {
//   return (
//     <div onClick={onClick} className='z-1 absolute hidden -top-0 lg:-top-16 right-0  cursor-pointer sm:inline-block transition-all duration-0.5 hover:bg-on-secondary-container bg-secondary p-1.5 md:p-3 2xl:p-4 rounded-lg'>{Icons.rightWihteArrow}</div>
//   )
// }


// const UpcommingEvent = ({ wrCustomClass = "" }) => {
//   const [data, setData] = useState([...slideDataArray])

//   var settings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     nextArrow: <SampleNextArrow />,
//     prevArrow: <SamplePrevArrow />,
//     responsive: [
//       {
//         breakpoint: 640,
//         settings: {
//           dots: true,
//         }
//       },
//     ]
//   };


//   let datarenderView = null;
//   datarenderView = data.map((item) => (
//     <div key={item.id}>
//       {
//         item?.data?.map((single) => (
//           <div key={single.id} className="pb-10 group sm:last:pb-0">
//             <h4 className='text-gray-800 group-hover:text-primary transition duration-0.5 lg:text-2xl md:text-xl text-lg font-bold m-0 break-words'>{single.title}</h4>
//             <h5 className="text-secondary pt-2 pb-4 text-base font-bold m-0 break-words">{single.date}</h5>
//             <p className='leading-7 font-normal text-lg text-gray-700 m-0 break-words'>{single.content}</p>
//             <div className='flex items-center gap-2 pt-4'>
//               <svg className='flex-shrink-0' width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 0C4.006 0 .757 3.25.757 7.243c0 4.957 6.482 12.233 6.758 12.54a.65.65 0 0 0 .97 0c.276-.307 6.758-7.583 6.758-12.54C15.243 3.25 11.994 0 8 0Zm0 10.887a3.648 3.648 0 0 1-3.644-3.644A3.648 3.648 0 0 1 8 3.6a3.648 3.648 0 0 1 3.644 3.644A3.648 3.648 0 0 1 8 10.887Z" fill="#7E1810" /></svg>
//               <h5 className="text-on-primary-container text-base font-bold m-0 break-words">{single.location}</h5>
//             </div>
//           </div>
//         ))
//       }
//     </div>
//   ))


//   return (
//     <div className={`${wrCustomClass} upcomming_event`}>
//       <div className='container mx-auto px-4 mb-14'>
//         <h4 className='pb-2 text-primary font-bold text-sm m-0 break-words uppercase tracking-widest'>Upcoming Evernts</h4>
//         <h2 className='text-gray-900 max-w-lg pb-4 text-xl lg:text-5xl md:text-3xl font-bold m-0 break-words'>Join Upcoming Events Replays & Webinars</h2>
//         <p className='lg:max-w-5xl max-w-3xl leading-7 font-normal text-lg text-gray-700 m-0 break-words'>
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit. In id augue semper arcu enim viverra sit ipsum. Nunc lorem a vulputate eget bibendum id et. Eget nunc, eu, molestie tellus sollicitudin vitae consequat adipiscing.
//         </p>
//       </div>
//       <div className='relative  pb-0 md:pb-12'>
//         <div className='mb-4 lg:mb-0 lg:absolute h-full top-0 left-0 lg:pr-10 w-full lg:w-1/2'>
//           <img className='px-5 md:px-0 rounded-br-100 object-cover h-full w-full' src={upcommingImg} alt="decause" />
//         </div>
//         <div className='container mx-auto px-4 '>
//           <div className='grid grid-cols-1 lg:grid-cols-2 gap-9'>
//             <div></div>
//             <Slider1 {...settings}>
//               {datarenderView}
//             </Slider1>

//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default UpcommingEvent





import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Icons from '../global/Icons';
import latestImg from '../../assets/images/1.jpg';
import latestImg2 from '../../assets/images/2.jpg';
import latestImg3 from '../../assets/images/3.jpg';
import { useNavigate } from "react-router-dom";

const Card = ({ item }) => {
  return (
    <div className='card-container group overflow-hidden' key={item.id}>
      <div className='h-auto sm:min-h-80'>
        <img
          className="object-cover h-full sm:min-h-80 w-full group-hover:scale-110 transition-all duration-500 rounded-t-md"
          src={item.img}
          alt="latest"
        />
      </div>
      <div className='bg-white relative p-4'>
        <h4 className='py-1 sm:py-4 truncate text-gray-900 lg:text-2xl md:text-xl text-lg font-bold m-0 break-words'>
          {item.title}
        </h4>
        {/* <Link
          to={item.link}
          className='flex gap-2 items-center text-on-tertiary-container text-lg sm:text-base font-bold m-0 break-words'
        >
          Learn More
          {Icons.rightArrowIcon}
        </Link> */}
      </div>
    </div>
  );
};

const UpcommingEvent = ({ wrCustomClass = "" }) => {
  const [visibleCards, setVisibleCards] = useState(3); // Initially show 3 cards


  const latestArray = [
    { id: 1, title: "Program Name", link: "#", img: latestImg },
    { id: 2, title: "Program Name", link: "#", img: latestImg2 },
    { id: 3, title: "Program Name", link: "#", img: latestImg3 },
    { id: 4, title: "Program Name", link: "#", img: latestImg2 },
    { id: 5, title: "Program Name", link: "#", img: latestImg3 },
  ];


  const contentViewRender = latestArray.slice(0, visibleCards).map((item) => (
    <Card key={item.id} item={item} />
  ));

  
  const navigate = useNavigate();
  const handleViewMore = () => {
    // setVisibleCards((prevCount) => prevCount + 3); // Show 3 more cards
    navigate("/gallery");
  };

  return (
    <div className={`py-12 sm:py-20 ${wrCustomClass}`} style={{paddingTop:'0rem'}}>
      <div className='container mx-auto px-4 text-center'>
        <h4 className='text-primary pb-2 font-bold text-sm m-0 break-words uppercase tracking-widest'>
          Gallery
        </h4>
        <h2 className='text-gray-900 mx-auto w-full max-w-lg pb-4 text-xl lg:text-4xl md:text-3xl font-bold m-0 break-words'>
          Keep memories in these posts
        </h2>
        <p className='leading-7 lg:max-w-5xl max-w-3xl mx-auto w-full pb-12 font-normal text-lg text-gray-700 m-0 break-words'>
          Our work has been featured in local news outlets for our efforts in improving education and healthcare services in the community.
        </p>
      </div>

      
      <div className='container mx-auto px-4'>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
          {contentViewRender}
        </div>
      </div>

      
      {visibleCards < latestArray.length && (
        <div className="text-center mt-8">
          <button
          //  style={{backgroundColor:'red'}}
            onClick={handleViewMore}
            // className="view-more-btn px-6 py-2 text-lg font-semibold text-white bg-blue-500 hover:bg-blue-600 rounded-md"
            className="rounded-3xl py-2 px-6 text-base font-bold tracking-0.5 transition-all duration-0.5 text-white hover:text-on-primary-container border-2 border-primary hover:bg-primary-container bg-primary hover:border-primary-container"

          >
            View More
          </button>
        </div>
      )}
    </div>
  );
};

export default UpcommingEvent;


const styles = `
  .card-container {
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    transition: all 0.3s ease;
  }

  .view-more-btn {
    transition: background-color 0.3s ease;
  }

  .view-more-btn:hover {
    background-color: #1e40af;
  }
`;


const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
