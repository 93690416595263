// import React from 'react'

// function Achievements() {
//   return (
//     <div style={{width:'500px',height:'600px'}}>Achievements</div>
//   )
// }

// export default Achievements

import { React, useEffect } from "react";
import BreadCrumb from "../../components/global/breadCrumb/BreadCrumb";
import award from "../../assets/images/award.jpg";
import certificate from "../../assets/images/trust-registration.jpg";


const CharityPage = () => {
  const breadCrumbArray = [
    {
      id: 1,
      title: "Home",
      link: "/",
      active: false,
    },
    {
      id: 2,
      title: "Achievements",
      link: "/achievements ",
      active: true,
    },
  ];

  useEffect(() => {
    document.title = "Armor Charitable Trust||Achievements ";
  }, []);

  return (
    <div className='payment'>
        <BreadCrumb title="Achievements" breadCrumbArray={breadCrumbArray} />

    <div style={styles.container}>
      {/* <section style={styles.section}>
        <h2 style={{color:'red',fontWeight:'bold',fontSize:'30px'}}>Awards</h2>
        <p style={styles.text}>
          Recognized by local and state authorities for outstanding contributions to social welfare.
        </p>
        <img 
          src={award} 
          alt="Award Recognition" 
          style={styles.image} 
        />
      </section> */}

      <section style={styles.section}>
          <h2 style={{ color: "red", fontWeight: "bold", fontSize: "30px" }}>
            Awards
          </h2>
        <p style={styles.text}>
          Recognized by local and state authorities for outstanding
          contributions to social welfare.
        </p>
        <img
          src={award}
          alt="Award Recognition"
          style={{ ...styles.image, display: "block", margin: "auto",width:'500',height:'190px',borderRadius:'20px' }}
        />
      </section>

      <section style={styles.section}>
        <h2 style={{ color: "red", fontWeight: "bold", fontSize: "30px" }}>
          Certifications
        </h2>
        <p style={styles.text}>
          Registered as a recognized charitable organization in Kerala and
          India.
        </p>
        <img
          src={certificate}
          alt="Certification"
          style={{ ...styles.image,display: "block", margin: "auto",width:'500',height:'190px',borderRadius:'20px'}}
        />
      </section>
    </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "'Arial', sans-serif",
    textAlign: "center",
    padding: "20px",
    backgroundColor: "#f4f4f9",
    color: "#333",
  },
  header: {
    backgroundColor: "#4CAF50",
    color: "white",
    padding: "50px 20px",
    marginBottom: "40px",
  },
  section: {
    margin: "30px 0",
    padding: "20px",
    backgroundColor: "white",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  text: {
    fontSize: "18px",
    marginBottom: "20px",
  },
  image: {
    maxWidth: "50%%",
    height: "50%",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
};

export default CharityPage;
