import React from 'react'
import { useEffect } from 'react'
import BreadCrumb from '../../components/global/breadCrumb/BreadCrumb'
import AboutSecetion from '../../components/aboutSection/AboutSecetion'
import BecomeYoutuber from '../../components/becomeYoutuber/BecomeYoutuber'
import DecauseWeCam from '../../components/decauseWeCan/DecauseWeCam'
import Feature from '../../components/feature/Feature'
import MeetOurTeam from '../../components/meetOurTeam/MeetOurTeam'
import QuestionSection from '../../components/questionSection/QuestionSection'
import missionImg from '../../assets/images/mission.jpeg'
// import aboutImg from '../../assets/images/mission.jpeg'
import aboutImg from '../../assets/images/joel-muniz-3k3l2brxmwQ-unsplash.jpg'




const AboutUs = () => {
  const breadCrumbArray = [
    {
      id:1,
      title:'Home',
      link:'/',
      active:false,
    },
    {
      id:2,
      title:'About',
      link:'/about-us',
      active:true,
    }
  ]
  useEffect(()=>{
    document.title="Armor Charitable Trust||About us"
  },[])
  return (
    <div className='about_us'>
        <BreadCrumb title="About Us" breadCrumbArray={breadCrumbArray} />
        <AboutSecetion/>
        
        {/* <DecauseWeCam/>
        <MeetOurTeam/>
        <QuestionSection/> */}
        {/* <BecomeYoutuber/> */}
        {/* <div className='pb-12 sm:pb-20'>
          <Feature/>
        </div> */}

<div className='grid grid-cols-1 md:grid-cols-2 gap-10 p-8'>
  <div className='flex justify-center items-center'>
    <div>
      <h4 className='text-primary font-bold text-sm m-0 break-words uppercase tracking-widest'>
        Our Mission
      </h4>
      <h2 className='pt-2 text-gray-900  font-bold m-0 break-words'>
        Empowering Communities Through Holistic and Sustainable Development
      </h2>
      <p className='pt-2 leading-7 font-normal text-lg text-gray-700 m-0 break-words'>
        To enhance the spiritual, educational, cultural, and economic development of communities through inclusive and sustainable programs.
      </p>
    </div>
  </div>
  <div>
    <img style={{
              transition: "transform 0.3s ease", // Smooth transition for the transformation
              cursor: "pointer", // Change cursor to pointer on hover
            }} 
            onMouseOver={(e) =>
              (e.currentTarget.style.transform = "scale(1.05)")
            } // Scale up on hover
            onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
            className='rounded-bra-100 object-cover' src={missionImg} alt="img" />
  </div>
</div>



<div className='grid grid-cols-1 md:grid-cols-2 gap-10 p-8'>
                <div>
                    <img 
                    style={{
                      transition: "transform 0.3s ease", // Smooth transition for the transformation
                      cursor: "pointer", // Change cursor to pointer on hover
                    }} 
                    onMouseOver={(e) =>
                      (e.currentTarget.style.transform = "scale(1.05)")
                    } // Scale up on hover
                    onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
                    className='rounded-bra-100 object-cover' src={aboutImg}  alt="img"/>
                </div>
                <div className='flex justify-center items-center'>
                    <div>
                        <h4 className='text-primary font-bold text-sm m-0 break-words uppercase tracking-widest'>Our Vision</h4>
                        <h4 className='pt-2 text-gray-900   font-bold m-0 break-words'>Driving Social Change Through Education, Healthcare, and Welfare</h4>
                        {/* <h4 className='pt-4 text-gray-900 lg:text-2xl md:text-xl text-lg font-bold m-0 break-words'>Helping them</h4> */}
                        <p className='pt-2 leading-7 font-normal text-lg text-gray-700 m-0 break-words'>
                        To be a catalyst for social change by providing education, healthcare, and welfare services to the underprivileged, ensuring a brighter and more equitable future.   
                        </p>
                                             {/* <button className='mt-10 rounded-3xl py-2 px-6 text-base font-bold tracking-0.5 transition-all duration-0.5 text-white hover:text-primary border-2 border-primary bg-primary hover:bg-transparent'>
                            Learn More
                        </button> */}
                    </div>
                </div>
            </div>


            
    </div>

    
  )
}

export default AboutUs