import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import campaignImg1 from '../../assets/images/campaign1.jpeg'
import campaignImg1 from '../../assets/images/banner-1.jpg'

// import campaignImg2 from '../../assets/images/campaign2.jpeg'
import campaignImg2 from '../../assets/images/da.jpg'


import campaignImg3 from '../../assets/images/campaign3.jpeg'
import ProgressLise from '../global/progressLine/ProgressLise'
import './campaign.css'


const data = [
    {
        id: 1,
        image: campaignImg1,
        // tag: 'Children',
        // btnTitle: "Donate Now",
        subtitle: "Public Relations",
        content: " Armor Charitable Trust has earned recognition for its dedication to community development and social service, with various media outlets featuring our projects on education and healthcare initiatives.",
        // percentage: '35',
        // Ralsed: "$5,000"
    },
    {
        id: 2,
        image: campaignImg2,
        // tag: 'Children',
        // btnTitle: "Donate Now",
        subtitle: "Industry Information",
        content: "Operating within the nonprofit sector, we are driven by a commitment to serve vulnerable populations, particularly focusing on education, healthcare, and community welfare programs in line with Gandhian ideals..",
        // percentage: '67',
        // Ralsed: "$5,000"
    },
    {
        id: 3,
        image: campaignImg3,
        // tag: 'Children',
        // btnTitle: "Donate Now",
        subtitle: "Safety, Health, and Environmental Policies",
        content: " We are committed to ensuring that all our activities are conducted with the highest regard for safety, health, and environmental sustainability. Our programs are designed to enhance community well-being while respecting environmental standards.",
        // percentage: '80',
        // Ralsed: "$5,000"
    },
]

const Campaign = () => {
    const [helpData, setHelpData] = useState([...data])

    let renderView = null;
    renderView = helpData.map((item) => (
        <div key={item.id} style={{backgroundColor:'none'}} className="campaign_single relative">
            <div className='z-1 inline-block absolute top-5 left-2 text-center py-2 px-4'>
                <h5 className="text-on-primary-container text-base font-bold">
                    {item.tag}
                </h5>
            </div>
            <div className='relative overflow-hidden rounded-t-md '>
                {/* <img className='h-80 object-cover w-full roun rounded-t-md' src={item.image} alt="dfd" /> */}
                <img
    className="h-80 object-cover w-full transition-transform duration-300 ease-in-out transform hover:scale-110"
    src={item.image}
    alt="Description of the image"
  />
                <div className='campaign_single_btn text-center py-3' >
                    <Link to="#" className='text-on-tertiary-container block text-lg sm:text-base font-bold m-0 break-words'>
                        {item.btnTitle}
                    </Link>
                </div>
            </div>
            <div
            //  style={{backgroundColor:'#6fb495'}}
            

               className='z-1 relative'>
                <h4 className='text-white pt-6 lg:text-xl md:text-xl text-lg font-bold m-0 break-words'>{item.subtitle}</h4>
                <p className='pt-4 pb-2 leading-7 font-normal text-lg text-white m-0 break-words'>{item.content}</p>
                {/* <ProgressLise height='h-3' percentage={item.percentage} /> */}
            </div>
            {/* <div className='flex justify-between pt-5'>
                <h5 className="text-white text-base font-bold">Ralsed {item.Ralsed}</h5>
                <h5 className="text-white text-base font-bold">Ralsed {item.Ralsed}</h5>

            </div> */}

        </div>
    ))

    return (
        <div style={{background: "linear-gradient(to bottom, #339966 0%, #006666 100%)"
            
        }} className={`py-12 sm:py-20 `}>
            <div className='container mx-auto px-4'>
                <div className='text-center pb-14 '>
                    {/* <h4 className='pb-1 text-tertiary-container font-bold text-sm m-0 break-words uppercase tracking-widest'>OUR CAMPAIGNS</h4> */}
                    {/* <h2 className='max-w-lg w-full mx-auto auto  font-bold text-secondary-container   pb-4 text-xl lg:text-5xl md:text-3xl m-0 break-words'>Our Highest Ambition is to Help People</h2> */}
                    <h2 style={{color:'white'}} className='max-w-lg w-full mx-auto auto  font-bold text-secondary-container   pb-4 text-xl lg:text-5xl md:text-3xl m-0 break-words'>History, Expansion, and Growth</h2>

                    <p className='max-w-3xl w-full mx-auto leading-7 font-normal text-lg text-white m-0 break-words'>
                    Armor Charitable Trust was established with the goal of serving the community, beginning its work in the Palakkad District. Over the years, we have expanded our operations across Kerala and other states in India, impacting lives through various charitable activities and educational initiatives.                    </p>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 gap-10 md:gap-6'>
                    {
                        renderView
                    }
                </div>
            </div>
        </div>
    )
}

export default Campaign