// import React, { useEffect } from "react";
// import { useState } from "react";
// import { Link } from "react-router-dom";
// import whiteLogo from "../../assets/images/white-logo.png";
// import Icons from "../../components/global/Icons";
// import { useNavigate } from "react-router-dom";

// const Footer = () => {
//   const [bottomClass, setBootomClass] = useState(false);
//   const scrollHandleScroll = () => {
//     if (window.scrollY > 500) {
//       setBootomClass(true);
//     } else {
//       setBootomClass(false);
//     }
//   };
//   const upToTopWindow = () => {
//     window.scrollTo({
//       top: 0,
//       behavior: "smooth",
//     });
//   };

//   const navigate = useNavigate();

//   const handleClick = () => {
//     navigate("/donation");
//   };

//   useEffect(() => {
//     window.addEventListener("scroll", scrollHandleScroll);
//   }, []);
//   return (
//     <div style={{backgroundColor:'#6fb495'}} className=" pt-20">
//       <div className="container mx-auto px-4">
//         <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
//           {/* <div>
//             <img src={whiteLogo} alt="footer logo" />
//             <button
//               onClick={handleClick}
//               className="mt-6 rounded-3xl py-2 px-6 text-base font-bold tracking-0.5 transition-all duration-0.5 text-white hover:text-primary border-2 border-primary bg-primary hover:bg-transparent"
//             >
//               Donate Now
//             </button>
//           </div> */}
//           <div>
//             <h4 className="pb-7 text-white lg:text-2xl md:text-xl text-lg font-bold m-0 break-words">
//               Quick Links
//             </h4>
//             <ul>
//               <li className="py-1" onClick={upToTopWindow}>
//                 <Link
//                   to="/"
//                   className="text-white hover:text-primary transition duration-0.5 text-base font-normal leading-7"
//                 >
//                   Home
//                 </Link>
//               </li>
//               {/* <li className="py-1" onClick={upToTopWindow}>
//                 <Link
//                   to="/causes"
//                   className="text-white hover:text-primary transition duration-0.5 text-base font-normal leading-7"
//                 >
//                   Causes
//                 </Link>
//               </li> */}
//               <li className="py-1" onClick={upToTopWindow}>
//                 <Link
//                   to="/about-us"
//                   className="text-white hover:text-primary transition duration-0.5 text-base font-normal leading-7"
//                 >
//                   About
//                 </Link>
//               </li>
//               {/* <li className="py-1" onClick={upToTopWindow}>
//                 <Link
//                   to="/event-page"
//                   className="text-white hover:text-primary transition duration-0.5 text-base font-normal leading-7"
//                 >
//                   Event
//                 </Link>
//               </li> */}

// <li className="py-1" onClick={upToTopWindow}>
//                 <Link
//                   to="/gallery"
//                   className="text-white hover:text-primary transition duration-0.5 text-base font-normal leading-7"
//                 >
//                   Gallery
//                 </Link>
//               </li>

//               <li className="py-1" onClick={upToTopWindow}>
//                 <Link
//                   to="/achievements"
//                   className="text-white hover:text-primary transition duration-0.5 text-base font-normal leading-7"
//                 >
//                   Achievements
//                 </Link>
//               </li>
//               <li className="py-1" onClick={upToTopWindow}>
//                 <Link
//                   to="/contact"
//                   className="text-white hover:text-primary transition duration-0.5 text-base font-normal leading-7"
//                 >
//                   Contact Us
//                 </Link>
//               </li>
//             </ul>
//           </div>
//           <div>
//             <h4 className="pb-7 text-white lg:text-2xl md:text-xl text-lg font-bold m-0 break-words">
//               Contact US
//             </h4>
//             <div className="flex items-center gap-2 w-60 py-1">
//               <div className="flex-shrink-5" style={{paddingBottom:'110px'}}>{Icons.locationIcon}</div>
//               <p className="leading-7 font-normal text-lg text-white m-0 break-words">
//                 16/538, Opp. Girls Higher Secondary School, Alathur P.O.,
//                 Alathur Taluk, Palakkad District, Kerala, India - 678 541
//               </p>
//             </div>
//             <div className="flex items-center gap-2 py-1">
//               {Icons.emialIcon}
//               <p className="leading-7 font-normal text-lg text-white m-0 break-words">
//               info@armortrust.org
//               </p>
//             </div>
//             <div className="flex items-center gap-2 py-1">
//               {Icons.callIcon}
//               <p className="leading-7 font-normal text-lg text-white m-0 break-words">
//                 +91 0000000000
//               </p>
//             </div>
//             <div className="flex justify-start gap-4 mt-4 items-center justify-start">
//               <Link to="/" className="cursor-pointer rounded-md">
//                 {Icons.fbIcon}
//               </Link>
//               <Link to="/" className="cursor-pointer rounded-md">
//                 {Icons.linkedinIcon}
//               </Link>
//               <Link to="/" className="cursor-pointer rounded-md">
//                 {Icons.twitterIcon}
//               </Link>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="border-t-2 border-on-primary-opacity-0.12 mt-12 py-10">
//         <div className="container text-center lg:text-left mx-auto px-4">
//           <div className="flex flex-col gap-y-4 lg:flex-row justify-between">
//             <div className="text-white text-base font-normal leading-6 text-center">
//               {" "}
//               © 2024 Armor Charitable Trust 
//               {/* <Link to="#" className="text-tertiary mr-0.5">
//                 {" "}
//                 Sitemap |
//               </Link>
//               <Link to="#" className="text-tertiary">
//                 {" "}
//                 Privacy Policy |
//               </Link>
//               <Link to="#" className="text-tertiary">
//                 {" "}
//                 Anti-Spam Policy |{" "}
//               </Link>
//               <Link to="#" className="text-tertiary">
//                 {" "}
//                 Click for Accessibility
//               </Link>
//             </div>
//             <div className="text-white text-base font-normal leading-6">
//               {" "}
//               Marketing & Web Design by
//               <Link to="#" className="text-tertiary">
//                 {" "}
//                 Devdesign Studio
//               </Link> */}
//             </div>
//           </div>
//         </div>
//       </div>

//       <div
//         className={`${
//           !bottomClass && "hidden "
//         } cursor-pointer fixed bottom-10 z-40 right-6 w-10 md:w-12 h-10 md:h-12 bg-gray-800 rounded-lg border-2 border-white flex items-center justify-center`}
//         onClick={upToTopWindow}
//       >
//         <svg
//           width="10"
//           height="22"
//           viewBox="0 0 10 22"
//           fill="none"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             d="M4.67033 0.128333L0.215333 5.01417C0.105333 5.15167 0.0869993 5.29833 0.151166 5.46333C0.215333 5.62833 0.343666 5.70167 0.517833 5.70167H3.37783V21.5967C3.37783 21.7158 3.4145 21.8167 3.48783 21.89C3.56117 21.9633 3.662 22 3.78117 22H6.22867C6.34783 22 6.44867 21.9633 6.522 21.89C6.59533 21.8167 6.632 21.7158 6.632 21.5967V5.70167H9.48283C9.657 5.70167 9.77617 5.61917 9.8495 5.46333C9.91367 5.29833 9.89533 5.15167 9.77617 5.01417L5.27533 0.128333C5.18367 0.0458333 5.08283 0 4.96367 0C4.85367 0 4.75283 0.0458333 4.67033 0.128333Z"
//             fill="white"
//           />
//         </svg>
//       </div>
//     </div>
//   );
// };

// export default Footer;



import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import whiteLogo from "../../assets/images/armor-logo.png";
import Icons from "../../components/global/Icons";

const Footer = () => {
  const [bottomClass, setBottomClass] = useState(false);
  const scrollHandleScroll = () => {
    if (window.scrollY > 500) {
      setBottomClass(true);
    } else {
      setBottomClass(false);
    }
  };

  const [hoverIndex, setHoverIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(null);
  };

  

  const upToTopWindow = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/donation");
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollHandleScroll);
  }, []);

  return (
    <div style={{ background: "linear-gradient(to bottom, #339966 0%, #006666 100%)", padding: "40px 0" }}>
      <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "0 20px" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
            gap: "20px",
            color: "#fff",
          }}
        >
          {/* Logo & Donate Section */}
          <div style={{ textAlign: "center" }}>
            <img
              src={whiteLogo}
              alt="Footer Logo"
              style={{ width: "150px", marginBottom: "20px" }}
            />
              <div>
            {/* <h4 style={{ marginBottom: "20px", fontSize: "20px" }}>Contact</h4> */}
            <div style={{ marginBottom: "10px", display: "flex", gap: "10px" ,marginTop:'5px'}}>
            <div style={{marginTop:'5px'}} className="flex-shrink-0">
                        {Icons.locationIcon}
                      </div>
              <p>
                16/538, Opp. Girls H  SS<br/> Alathur P.O., Palakkad
              </p>
            </div>
            <div style={{ marginBottom: "10px", display: "flex", gap: "10px" }}>
              {Icons.emialIcon}
              <p>info@armortrust.org</p>
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              {Icons.callIcon}
              <p>+91 8304059936</p>
            </div>
          </div>
            {/* <button
              onClick={handleClick}
              style={{
                backgroundColor: "#e74c3c",
                color: "#fff",
                padding: "10px 20px",
                borderRadius: "30px",
                border: "none",
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              Donate Now
            </button> */}
          </div>

          {/* Quick Links */}
          <div style={{ display: "flex", justifyContent: "space-around" }}>
  {/* Quick Links Section */}
  {/* <div>
    <h4 style={{ marginBottom: "20px", fontSize: "20px" }}>Quick Links</h4>
    <ul style={{ listStyle: "none", paddingLeft: "0" }}>
      {["/", "/about-us", "/gallery", "/achievements", "/contact"].map((path, index) => (
        <li key={index} style={{ marginBottom: "10px" }} onClick={upToTopWindow}>
          <Link to={path} style={{ color: "#fff", textDecoration: "none" }}>
            {path === "/" ? "Home" : path.replace("/", "").replace("-", " ").replace(/\b\w/g, l => l.toUpperCase())}
          </Link>
        </li>
      ))}
    </ul>
  </div> */}

<div>
      <h4 style={{ marginBottom: "20px", fontSize: "20px" }}>Quick Links</h4>
      <ul style={{ listStyle: "none", paddingLeft: "0" }}>
        {["/", "/about-us", "/gallery", 
        // "/achievements",
         "/contact-us"].map((path, index) => (
          <li
            key={index}
            style={{ marginBottom: "10px" }}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            onClick={upToTopWindow}
          >
            <Link
              to={path}
              style={{
                color: hoverIndex === index ? "red" : "#fff",
                textDecoration: "none",
              }}
            >
              {path === "/"
                ? "Home"
                : path.replace("/", "").replace("-", " ").replace(/\b\w/g, (l) => l.toUpperCase())}
            </Link>
          </li>
        ))}
      </ul>
    </div>

  {/* Services Section */}
  {/* <div>
    <h4 style={{ marginBottom: "20px", fontSize: "20px" }}>Services</h4>
    <ul style={{ listStyle: "none", paddingLeft: "0" }}>
      {["Education", "Healthcare", "Social Welfare", "Charitable Activities", "Cultural Programs"].map((service, index) => (
        <li key={index} style={{ marginBottom: "10px" }}>
          <Link to="" style={{ color: "#fff", textDecoration: "none" }}>
            {service}
          </Link>
        </li>
      ))}
    </ul>
  </div> */}

  <div>
  <h4 style={{ marginBottom: "20px", fontSize: "20px" }}>Services</h4>
  <ul style={{ listStyle: "none", paddingLeft: "0" }}>
    {["Education", "Healthcare", "Social Welfare", "Cultural Programs", "Charitable Activities"].map((service, index) => (
      <li key={index} style={{ marginBottom: "10px" }}>
        <Link 
          to="" 
          style={{ 
            color: "#fff", 
            textDecoration: "none",
            transition: "color 0.3s" // Smooth transition for hover effect
          }}
          onMouseEnter={(e) => e.target.style.color = "red"}
          onMouseLeave={(e) => e.target.style.color = "#fff"}
        >
          {service}
        </Link>
      </li>
    ))}
  </ul>
</div>
</div>


          {/* Contact Section */}
        
        </div>
      </div>

      {/* Back to Top Button */}
      <div
        className={`${!bottomClass && "hidden"}`}
        style={{
          position: "fixed",
          bottom: "30px",
          right: "30px",
          backgroundColor: "#DA291C",
          color: "#fff",
          padding: "10px",
          borderRadius: "18%",
          cursor: "pointer",
          zIndex: "1000",
        }}
        onClick={upToTopWindow}
      >
        ↑
      </div>

      {/* Copyright Section */}
      <div
        style={{
          borderTop: "1px solid #fff",
          paddingTop: "20px",
          textAlign: "center",
          marginTop: "40px",
          color: "#fff",
          fontWeight:"bold"
          // color:"rgba(255, 218, 212, 0.12)"
        }}
      >
        <p>© 2024 Armor Charitable Trust. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
