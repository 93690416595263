import {React,useEffect} from 'react';
import BreadCrumb from "../../components/global/breadCrumb/BreadCrumb";


const Optionals = () => {
  const styles = {
    container: {
      fontFamily: 'Arial, sans-serif',
      margin: '0 auto',
      padding: '20px',
      maxWidth: '1200px',
      textAlign: 'center',
      backgroundColor: '#f9f9f9',
      borderRadius: '8px',
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    },
    header: {
      color: '#2c3e50',
      fontSize: '2.5rem',
      marginBottom: '20px',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '20px',
    },
    card: {
      borderRadius: '8px',
      backgroundColor: '#ecf0f1',
      padding: '15px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    },
    image: {
      width: '100%',
      borderRadius: '8px',
      marginBottom: '10px',
    },
    subHeader: {
      color: '#2980b9',
      fontSize: '1.8rem',
      margin: '10px 0',
    },
    paragraph: {
      color: '#34495e',
      fontSize: '1.1rem',
    },
  };



  
    const breadCrumbArray = [
      {
        id: 1,
        title: "Home",
        link: "/",
        active: false,
      },
      {
        id: 2,
        title: "Optionals",
        link: "/optionals ",
        active: true,
      },
    ];
  
    useEffect(() => {
      document.title = "Armor Charitable Trust||Optionals ";
    }, []);

  return (
    <div>
<BreadCrumb title="Contact" breadCrumbArray={breadCrumbArray} />

    <div style={styles.container}>
      <h1 style={styles.header}>Our Charity Trust</h1>
      <div style={styles.grid}>
        <div style={styles.card}>
          <img
            src="https://via.placeholder.com/400x200"
            alt="Charity Work"
            style={styles.image}
          />
          <h2 style={styles.subHeader}>Annual Sales</h2>
          <p style={styles.paragraph}>[Insert financial information if applicable.]</p>
        </div>

        <div style={styles.card}>
          <img
            src="https://via.placeholder.com/400x200"
            alt="Financial Target"
            style={styles.image}
          />
          <h2 style={styles.subHeader}>Financial Targets</h2>
          <p style={styles.paragraph}>
            To continue expanding charitable activities, we aim to raise [Insert Financial Target] by [Insert Year].
          </p>
        </div>

        <div style={styles.card}>
          <img
            src="https://via.placeholder.com/400x200"
            alt="Our Team"
            style={styles.image}
          />
          <h2 style={styles.subHeader}>Number of Employees</h2>
          <p style={styles.paragraph}>
            The Trust currently employs [Insert Number] staff members who work alongside volunteers to deliver services.
          </p>
        </div>

        <div style={styles.card}>
          <img
            src="https://via.placeholder.com/400x200"
            alt="Partners"
            style={styles.image}
          />
          <h2 style={styles.subHeader}>Partners</h2>
          <p style={styles.paragraph}>
            We collaborate with several local NGOs, healthcare providers, and educational institutions to maximize our impact.
          </p>
        </div>
      </div>
    </div>

    </div>
  );
};

export default Optionals;
