const Menu = [
    {
        id:1,
        title:'Home',
        to:'/',
        droupMenu:false,
        droupMenuItemList:[
            {
                id:1,
                title:''
            }
        ]
    },
    // {
    //     id:2,
    //     title:'Causes',
    //     to:'/causes',
    //     droupMenu:false,
    //     droupMenuItemList:[
    //         {
    //             id:1,
    //             title:''
    //         }
    //     ]
    // },

    {
        id:2,
        title:'About',
        to:'/about-us',
        droupMenu:false,
        droupMenuItemList:[
            {
                id:1,
                title:''
            }
        ]
    },
    // {
    //     id:3,
    //     title:'Pages',
    //     to:'!#',
    //     droupMenu:true,
    //     droupMenuItemList:[
    //         {
    //             id:1,
    //             title:'About Us',
    //             to:'/about-us'
    //         },
    //         {
    //             id:2,
    //             title:'Meet Team',
    //             to:'/meet-team'
    //         },
    //         {
    //             id:3,
    //             title:'Become Volunteer',
    //             to:'/become-volunteer'
    //         },
    //         {
    //             id:4,
    //             title:'Faq',
    //             to:'/faq'
    //         },
    //         {
    //             id:5,
    //             title:'Causes',
    //             to:'/causes'
    //         },
    //         {
    //             id:6,
    //             title:'Causes Details',
    //             to:'/causes-details'
    //         },
    //         {
    //             id:7,
    //             title:'Donation',
    //             to:'/donation'
    //         },
    //         {
    //             id:8,
    //             title:'Donation Payment',
    //             to:'/donation-payment'
    //         },
    //         {
    //             id:9,
    //             title:'Event',
    //             to:'/event-page'
    //         },
    //         {
    //             id:10,
    //             title:'Event Detail',
    //             to:'/event-detail'
    //         },
    //         {
    //             id:11,
    //             title:'Contact Us',
    //             to:'/contact-us'
    //         },
    //         {
    //             id:12,
    //             title:'Error Page',
    //             to:'/error',
               
    //         }
    //     ]
    // },
    // {
    //     id:4,
    //     title:'About US',
    //     to:'/about-us',
    //     droupMenu:false,
    //     droupMenuItemList:[  
    //     ]
    // },

     {
        id:4,
        title:'Gallery',
        to:'/gallery',
        droupMenu:false,
        droupMenuItemList:[  
        ]
    },

    // {
    //     id:5,
    //     title:'Achievments',
    //     to:'achievements',
    //     droupMenu:false,
    //     droupMenuItemList:[
    //         {
    //             id:1,
    //             title:''
    //         }
    //     ]
    // },
    // {
    //     id:6,
    //     title:'Event',
    //     to:'/event-page',
    //     droupMenu:false,
    //     droupMenuItemList:[
    //         {
    //             id:1,
    //             title:''
    //         }
    //     ]
    // },
    {
        id:7,
        title:'Contact US',
        to:'/contact-us',
        droupMenu:false,
        droupMenuItemList:[
            {
                id:1,
                title:''
            }
        ]
    },
    
]

export default Menu;